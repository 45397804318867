export interface IPrice {
  gross: number;
  currency: string;
  discount?: {
    compared_price: number;
    name: string;
    type: typeDiscount | null;
    type_display: string;
  } | null;
  final?: number;
}

export enum TYPE_DISCOUNT {
  EXPIRATION_DISCOUNT = "expiration_discount",
  CUSTOMER_DISCOUNT = "customer_discount",
  PROMOFIT = "promofit",
  FLASH = "flash_sale",
}

export type typeDiscount = "expiration_discount" | "customer_discount" | "promofit" | "flash_sale";

export interface IGetAvailableProductsResponse {
  success: boolean;
  message: string;
  payload: Array<{
    custom_label: string | null;
    custom_label_background_color: string | null;
    id: number;
    sku: string;
    price: IPrice;
    // price_undiscounted: IPrice;
    quantity_available: number;
    is_available: boolean;
    on_sale: boolean;
    step_qty?: number;
    measure_type_display_short?: string | null;
  }>;
}

interface IAddressQuickStart {
  id: number;
  alias: string | null;
  address: string;
  reference: string | null;
  city: string;
  latitude: string;
  longitude: string;
}

export interface QuickStartResponse {
  success: boolean;
  payload: {
    userPaymentMethod: {
      id: number;
      alias: string | null;
      paymentGatewayData: {
        name: string;
        description: string;
      };
      paymentMethod: number;
    };
    shippingAddress?: IAddressQuickStart | null;
    serviceAppointmentType: "at_home" | "on_site";
    lastDeliveryOrder?: IAddressQuickStart | null;
  };
  message: string;
}
