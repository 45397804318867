import { useEffect, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CheckoutEnum } from "@/recourses/queryKeys";
import { cartStoresActions } from "@/store/cartStore";
import { getCartAvailableProducts } from "@/services/checkout";
import { TYPE_DISCOUNT, type IGetAvailableProductsResponse } from "@/services/checkout/type";
import useAppSelector from "./useAppSelector";
import useAppDispatch from "./useAppDispatch";
import useCheckoutSummary from "./useCheckoutSummary";

interface IUseKeepCartFresh {
  onValidate?: (data: IGetAvailableProductsResponse) => void;
  omit?: boolean;
  items?: ProcessedItems[];
  revalidateWhen?: Array<boolean | string | number | null | undefined>;
}

const useKeepCartFresh = ({
  onValidate,
  omit = false,
  items: argsItems,
  revalidateWhen,
}: IUseKeepCartFresh = {}) => {
  const dispatch = useAppDispatch();
  const storeId = useAppSelector((state) => state.cart.storeId);
  const { processedItems: items } = useCheckoutSummary();
  const userId = useAppSelector((state) => state.user?.id);

  const itemsRef = useRef(items);

  let localItems = itemsRef.current;
  if (argsItems) {
    localItems = argsItems;
  }

  // Get cart available products
  const { data: cartAvailables } = useQuery({
    queryKey: [
      CheckoutEnum.getCartAvailableProducts,
      +storeId!,
      JSON.stringify(itemsRef.current),
      argsItems,
      ...(revalidateWhen ?? []),
      userId,
    ],
    queryFn: async () => {
      const productsId = localItems.map((product) => String(product.product.sku)).join(",");
      return await getCartAvailableProducts(Number(storeId), productsId);
    },
    enabled: localItems.length > 0 && !!storeId && !omit,
    gcTime: 1000 * 60 * 1, // 1 minutes
  });

  const verifyAvailableProductsMutation = useMutation({
    mutationKey: [CheckoutEnum.getCartAvailableProducts],
    mutationFn: async (storeID: number) => {
      const productsId = localItems.map((product) => String(product.product.sku)).join(",");
      return await getCartAvailableProducts(Number(storeID), productsId);
    },
    onSuccess: (data) => {
      if (data) {
        handleChangeData(data);
      }
    },
  });

  const handleChangeData = (data: IGetAvailableProductsResponse | undefined) => {
    if (!data) return;

    localItems.forEach((item) => {
      const product = data?.payload.find((product) => String(product.sku) === String(item.product.sku));

      let customLabelInfo = {};
      const typeDiscount = product?.price?.discount?.type;
      const customColorBackground = product?.custom_label_background_color;

      if (
        product?.price?.discount &&
        ([TYPE_DISCOUNT.CUSTOMER_DISCOUNT, TYPE_DISCOUNT.PROMOFIT]?.includes(typeDiscount as TYPE_DISCOUNT) ||
          (typeDiscount === TYPE_DISCOUNT.EXPIRATION_DISCOUNT && customColorBackground))
      ) {
        customLabelInfo = {
          customLabel: typeDiscount,
          customLabelBackground: customColorBackground,
        };
      }

      if (!product) {
        dispatch(
          cartStoresActions.changeQuatityAvailable({
            productSKU: Number(item?.product.sku),
            maxQtyToOrder: 0,
            isAvailable: false,
          })
        );
        return;
      }

      dispatch(
        cartStoresActions.changeQuatityAvailable({
          productSKU: Number(item?.product.sku),
          maxQtyToOrder: product?.quantity_available ?? 0,
          isAvailable: product?.is_available ?? false,
          currency: product?.price?.currency ?? "COP",
          finalPrice: product?.price?.final ?? 0,
          originalPrice: product?.price?.gross ?? 0,
          onSale: product?.on_sale ?? false,
          stepQty: product?.step_qty ?? 1,
          measureTypeDisplayShort: product?.measure_type_display_short ?? null,
          ...customLabelInfo,
        })
      );
    });
  };

  useEffect(() => {
    if (cartAvailables) {
      handleChangeData(cartAvailables);
      onValidate?.(cartAvailables);
    }
  }, [JSON.stringify(cartAvailables?.payload)]);

  useEffect(() => {
    // Check duplicated products
    if (itemsRef.current.length > 0) {
      dispatch(cartStoresActions.removeDuplicatedProducts());
    }
  }, [itemsRef?.current]);

  return {
    verifyAvailableProductsMutation,
  };
};

export default useKeepCartFresh;
