import tw, { css, styled } from "twin.macro";
import BrandLogoFithub from "@/components/Atoms/BrandLogoFithub";
import { type TSize } from "@/types/common";
import { styledWrapperBase } from "@/styles/common";

export const styles = {
  root: {
    base: tw`w-full flex flex-col top-0 left-0 z-[50] bg-primaryV2-100  transition-[translate] duration-300 ease-in-out gap-s pt-s pb-s medium:(gap-sm) items-center`,
    scrolled: tw`shadow-elevation-2 animate-slideDown sticky`,
  },
  wrapper: {
    base: tw`w-full flex items-center justify-between gap-m medium:(gap-sm) large:(gap-ml)`,
  },
  body: {
    base: tw`flex items-end justify-between w-full medium:(gap-m)`,
  },
  logo: {
    base: tw`w-[4.938rem] h-[1.5rem]
      medium:(w-[5.563rem] h-[1.625rem])
      large:(w-[8.063rem] h-[2.125rem])
    `,
  },
  iconChevronDown: {
    base: tw`transition-transform duration-300 ease-in-out transform min-[750px]:(w-5 h-5)`,
    size: {
      small: tw`w-[1.125rem] h-[1.125rem]`,
      large: tw`w-6 h-6`,
    },
  },
  topNotification: {
    base: tw`w-auto`,
  },
  headerHeadLeft: {
    base: tw`flex items-center gap-sm w-[76%] medium:(gap-ml) large:(gap-m)`,
    scrolled: tw`large:(gap-ml) w-max`,
  },
  headerHeadRight: {
    base: tw`flex items-center justify-end gap-m medium:(gap-ml) `,
    scrolled: tw`w-full medium:(gap-m) large:(max-w-[50%])`,
  },
  headerButton: tw` mx-auto flex items-center justify-between pb-s px-xxs xxs:px-s medium:(px-m) large:(px-0)`,
  headerButtonLeft: {
    base: tw`flex items-center gap-ml`,
    isSmall: tw`w-full justify-between`,
  },
  headerButtonRight: tw`flex items-center gap-ml`,
};

export const StyledRootHeader = styled.header<{
  $size?: TSize;
  $isScrolled: boolean;
}>`
  ${styles.root.base}

  ${({ $isScrolled }) => {
    if ($isScrolled) {
      return styles.root.scrolled;
    }
  }}
`;

export const StyledHeaderHead = styled.div`
  ${styles.wrapper.base}
`;

export const StyledHeaderHeadLeft = styled.div<{ $isScrolled: boolean }>`
  ${styles.headerHeadLeft.base}

  ${({ $isScrolled }) => {
    if ($isScrolled) {
      return styles.headerHeadLeft.scrolled;
    }
  }}
`;
export const StyledHeaderHeadRight = styled.div<{ $isScrolled: boolean }>`
  ${styles.headerHeadRight.base}
  ${({ $isScrolled }) => {
    if ($isScrolled) {
      return styles.headerHeadRight.scrolled;
    }
  }}
`;

export const StyledHeaderBody = styled.div`
  ${styles.body.base}
`;

export const StyledLogoFithubPrimary = styled(BrandLogoFithub)`
  ${styles.logo.base}
`;

export const StyledContainer = styled.div`
  ${styledWrapperBase}
  ${tw`w-full px-xxs xxs:px-s flex flex-col gap-s medium:(px-m) large:(px-m)`}
`;

export const StyledPlaceholder = styled.div<{
  $height: number;
}>`
  ${({ $height }) => {
    return css`
      height: ${$height}px;
    `;
  }}
`;

export const StyledTopNotificationRoot = styled.div<{ $isScrolled: boolean }>`
  ${styles.topNotification.base}
`;

export const StyledHeaderButton = styled.div`
  ${styledWrapperBase}
  ${styles.headerButton}
`;

export const StyledHeaderButtonLeft = styled.div<{ $isSmall: boolean }>`
  ${styles.headerButtonLeft.base}

  ${({ $isSmall }) => $isSmall && styles.headerButtonLeft.isSmall}
`;

export const StyledHeaderButtonRight = styled.div`
  ${styles.headerButtonRight}
`;
