import { forwardRef, useEffect, useImperativeHandle, useRef, useState, type FormEvent } from "react";

import Button from "@atoms/Button";
import IconSearch from "@iconsV2/Interrface/magnifier-search-01.svg?react";
import IconEditClose from "@iconsV2/Edit/close.svg?react";
// import { useMediaQuery } from "@/hooks/useMediaQuery";
import type { TSize } from "@/types/common";
import { StyledContentInput, StyledInput, StyledSuggestion, StyledWrapperInput } from "./styles";

interface InputProps {
  size?: TSize;
  value: string;
  onChange: (value: string) => void;
  onSubmit?: (e: FormEvent) => void;
  onClick?: () => void;
  onClean?: () => void;
  suggestions?: string[];
  [key: string]: any;
}

const InputForm = forwardRef<HTMLInputElement, InputProps>((props, outerRef) => {
  const { size, value, onChange, onSubmit, onClean, onClick, suggestions, ...restProps } = props;
  // const isMedium = useMediaQuery("(min-width: {medium})");
  // const isLarge = useMediaQuery("(min-width: {large})");

  const [suggestion, setSuggestion] = useState("");
  const refContentInput = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(outerRef, () => innerRef.current!, []);

  const handleUpdateSuggestion = () => {
    if (value.length > 0) {
      const match = suggestions?.find((s) => s.toLowerCase().startsWith(value.toLowerCase()));
      // setSuggestion(match ? match.slice(value.length) : "");
      setSuggestion(
        `<span>${value?.toLowerCase()}</span>${match ? match.slice(value.length)?.toLowerCase() : ""}`
      );
    } else {
      setSuggestion("");
    }
  };

  useEffect(() => {
    handleUpdateSuggestion();
  }, [value, suggestions]);

  // const getTextWidth = () => {
  //   if (!innerRef.current) return;
  //   const canvas = document.createElement("canvas");
  //   const context = canvas.getContext("2d");
  //   // get the full font style property
  //   const font = window.getComputedStyle(innerRef.current, null).getPropertyValue("font");

  //   // set the font attr for the canvas text
  //   if (context) {
  //     context.font = font;
  //   }
  //   const textMeasurement = context?.measureText(value);

  //   return Math.floor(textMeasurement?.width ?? 0);
  // };

  // useEffect(() => {
  //   if (!innerRef.current || !refContentInput.current) return;

  //   if (value.length > 0) {
  //     const width = getTextWidth() ?? 0;
  //     // If width is more than maximum width use 100%
  //     if (width >= refContentInput.current?.clientWidth) {
  //       innerRef.current.style.width = "100%";
  //     } else {
  //       innerRef.current.style.width = width + "px";
  //     }
  //   } else {
  //     innerRef.current.style.width = "100%";
  //   }
  // }, [value, innerRef.current, refContentInput.current?.clientWidth, isMedium, isLarge]); // Medium and large are dependencies required for get a new font size width about that breakpoint

  const handleKeyDown = (e) => {
    if (e.key === "Tab" && suggestion) {
      e.preventDefault();
      onChange(suggestion.replace(/<[^>]*>?/gm, ""));
      setSuggestion("");
    }
  };

  return (
    <form {...restProps} onSubmit={onSubmit} tw="relative flex items-stretch w-full">
      <StyledWrapperInput $size={size}>
        <IconSearch tw="!(h-[18px] large:(h-ml))" />

        <StyledContentInput
          tw="relative"
          $size={size}
          onClick={() => innerRef.current?.focus()}
          ref={refContentInput}
        >
          <StyledInput
            ref={innerRef}
            type="text"
            title="Busca tus productos"
            placeholder="Busca tus productos"
            $size={size}
            value={value}
            onClick={onClick}
            onChange={(e) => onChange(e.target.value)}
            onFocus={handleUpdateSuggestion}
            onBlur={() => setSuggestion("")}
            onKeyDown={handleKeyDown}
          />

          <StyledSuggestion
            tw="absolute -left-[1px] font-bold text-neutral-40 [&>span]:(text-transparent)"
            dangerouslySetInnerHTML={{ __html: suggestion }}
          ></StyledSuggestion>
        </StyledContentInput>

        {value.length >= 1 && (
          <Button
            tw="mr-[2.6rem] ml-s medium:(mr-[2.8rem] ml-sm) large:(mr-12 ml-m)"
            onClick={onClean}
            sizeVariant="lg"
            color="secondary"
            variant="text"
            endIcon={<IconEditClose tw="[& path]:(fill-neutral-10!)" />}
            type="button"
          />
        )}
      </StyledWrapperInput>

      <Button
        title="Buscar"
        sizeVariant="lg"
        color="neutral"
        endIcon={<IconSearch tw="!(h-[18px] large:(h-[2.25rem]))" />}
        tw="absolute h-full top-0 right-0 rounded-lg medium:(px-s w-[2.25rem] h-[2.25rem])"
      />
    </form>
  );
});

export default InputForm;
