import type { ProductHit } from "@/recourses/meilisearch/meiliSearch";
import { MIN_AVAILABLE_QUANTITY } from "@/recourses/constants";
import type { RecombeeValuesItemUser } from "@/types/recombee";
import { calculatePercentageDiscount } from "./money";
import type { DiscountProductOrder, OrderProduct } from "@/services/ordersV2/type";
import type { IProduct } from "@/Pages/Product/type";
import { TYPE_DISCOUNT } from "@/services/checkout/type";

export const promofitOptions = ["normal", "promofit"];

export const baseProduct = {
  name: "Harina de Garbanzo - fithub x 250 g",
  brand: "Fithub",
  price: 5500,
  originalPrice: 5500,
  isNew: false,
  availabilityOnSaleOnline: true,
  imagen:
    "https://dexter-media-prod-co.s3.amazonaws.com/__sized__/products/79077149-crop-c0-5__0-5-255x255-90.jpg",
  brandSlug: "fithub",
  id: 1,
  quatityAvailable: 12,
  sku: "123456",
  slug: "harina-de-garbanzo-fithub-x-250-g",
  storeId: 1,
  storeType: "supermarket",
  isPromoted: false,
  bestSeller: false,
};

export const cartProduct: Cart = {
  storeId: "13",
  quantity: 1,
  stepQty: 1,
  product: {
    id: "1404",
    name: "Yogurt Griego con Stevia - fithub x 1 L",
    slug: "yogurt-griego-con-stevia-fithub-x-1-l",
    sku: 89028995,
    brand: {
      name: "fithub",
      slug: "fithub",
    },
    image: {
      alt: "Yogurt Griego con Stevia - fithub x 1 L",
      url: "https://dexter-media-staging-2-co.s3.amazonaws.com/__sized__/products/__filtered__/89028995__webp__-thumbnail-510x510-70.webp",
    },
  },
  details: {
    customLabel: "",
    customLabelBackgroundColor: "#000000",
    onSale: false,
    available: true,
    maxQtyToOrder: 23,
  },
  price: {
    currency: "COP",
    discount: 0,
    finalPrice: 41500,
    originalPrice: 41500,
    membership: 0,
    perMeasureUnitDisplay: 0,
  },
};

export const arrayProducts = Array.from({ length: 16 }).map((_) => cartProduct);

export const checkProductHasStock = (item: Cart) => {
  return (
    item.details.maxQtyToOrder > MIN_AVAILABLE_QUANTITY &&
    item.details.available &&
    item.quantity <= item.details.maxQtyToOrder
  );
};

export const recombeeToCart = (
  id: number,
  storeId: number,
  item: RecombeeValuesItemUser | ProductHit
): Cart => {
  let customLabel = item.custom_label ?? "";
  let customLabelBackgroundColor = item.custom_label_background_color ?? "";

  // Validate if the product is really antimerma to apply the discount type
  if (item.has_expiration_discount && item.custom_label) {
    customLabel = TYPE_DISCOUNT.EXPIRATION_DISCOUNT;
  }

  // If it is a fake antimerma, remove the custom label
  if (!item.has_expiration_discount && item.custom_label?.toLowerCase() === "antimerma") {
    customLabel = "";
    customLabelBackgroundColor = "";
  }

  return {
    storeId: String(storeId),
    quantity: 1,
    stepQty: item.step_qty ?? 1,
    product: {
      id: String(id),
      name: item.product_name,
      slug: item.product_slug,
      sku: +item.product_sku,
      brand: {
        name: item.product_brand,
        slug: item.product_brand_slug,
      },
      image: {
        alt: item.product_name,
        url: item.product_thumbnail_url,
      },
    },
    details: {
      onSale: item.product_availability_on_sale_online,
      available: item.product_availability_available_online,
      maxQtyToOrder: item.product_availability_max_qty_to_order,
      customLabel,
      customLabelBackgroundColor,
      isPromoted: item.is_promoted === 1,
      isNew: item.is_new,
      measureTypeDisplayShort: item.product_measure_type_display_short,
    },
    price: {
      currency: item.product_availability_currency,
      finalPrice: item.price_online_gross,
      originalPrice: item.price_online_undiscounted_gross,
      membership: item.price_online_membership_gross,
      discount: item.product_availability_discount_online,
    },
  };
};

export const cartToOrderProduct = (item: Cart, discount: DiscountProductOrder): OrderProduct => {
  return {
    ...discount,
    id: +item.product.id,
    product: {
      id: +item.product.id,
      brand: {
        id: +item.product.id,
        name: item?.product?.brand.name ?? "",
        slug: item.product.brand.slug ?? "",
      },
      image: item.product.image.url,
      name: item.product.name,
      sku: String(item?.product?.sku ?? ""),
      slug: item.product.slug,
      store_product_id: +(item.storeId ?? 0),
      measureTypeDisplayShort: item.details.measureTypeDisplayShort,
    },
    order_id: 0,
    quantity: item.quantity,
    stepQty: item.stepQty,
    total: item.price.originalPrice,
    total_discounted: item.price.finalPrice,
    unit_price_gross: item.price.originalPrice?.toString(),
    unit_price_discounted_gross:
      item.price.finalPrice === item?.price?.originalPrice ? null : item.price.finalPrice?.toString(),
  };
};

export const getPercentageDiscount = ({
  unitPriceDiscountedGross,
  discountType,
  unitPriceGross,
}: {
  unitPriceDiscountedGross?: string | null;
  discountType?: string | null;
  unitPriceGross?: string | null;
}) => {
  let percentage = 0;

  /* Calculate percentage */
  if (
    unitPriceDiscountedGross &&
    (promofitOptions.includes(discountType ?? "") ||
      discountType === TYPE_DISCOUNT.CUSTOMER_DISCOUNT ||
      discountType === TYPE_DISCOUNT.EXPIRATION_DISCOUNT)
  ) {
    percentage = calculatePercentageDiscount({
      priceOriginal: parseFloat(unitPriceGross ?? "0"),
      priceDiscount: parseFloat(unitPriceDiscountedGross ?? "0"),
    });
  }

  return percentage;
};

export const oldCartToNewCart = (storeId: number, item: OldCart | IProduct): Cart => {
  return {
    storeId: String(storeId),
    quantity: "quantity" in item ? item.quantity : 1,
    stepQty: item.stepQty,
    product: {
      id: String(item.pk),
      name: item.details.name,
      slug: item.details.slug,
      sku: item.details.sku,
      brand: {
        name: item.details.brand.name,
        slug: item.details.brand.slug,
      },
      image: {
        alt: item.details.thumbnail?.alt ?? item.details.name,
        url: item.details.thumbnail.url,
      },
    },
    details: {
      onSale: item.availability.onSale,
      available: item.availability.available,
      maxQtyToOrder: item.availability.maxQtyToOrder,
      customLabel: item?.customLabel ?? "",
      customLabelBackgroundColor: item?.customLabelBackgroundColor,
    },
    price: {
      currency: item?.availability?.price?.currency,
      finalPrice: item?.availability?.price?.gross ?? 0,
      originalPrice: item?.availability?.priceUndiscounted?.gross ?? 0,
      membership: item.availability.priceMembership?.gross ?? 0,
      discount: item.availability?.discount?.gross ?? item.availability?.discount ?? 0,
      perMeasureUnitDisplay:
        "pricePerMeasureUnitDisplay" in item.availability
          ? +(item.availability.pricePerMeasureUnitDisplay ?? 0)
          : undefined,
    },
  };
};

export const newCartToOldCart = (item: Cart): OldCart => {
  return {
    pk: String(item.product.id),
    quantity: item.quantity,
    customLabel: item.details.customLabel,
    customLabelBackgroundColor: item.details.customLabelBackgroundColor,
    availability: {
      onSale: item.details.onSale,
      available: item.details.available,
      maxQtyToOrder: item.details.maxQtyToOrder,
      price: {
        gross: item.price.finalPrice,
        currency: item.price.currency,
      },
      discount: {
        gross: item.price.discount,
        currency: item.price.currency,
      },
      priceUndiscounted: {
        gross: item.price.originalPrice,
        currency: item.price.currency,
      },
      priceMembership: {
        gross: item.price.membership,
        currency: item.price.currency,
      },
    },
    details: {
      name: item.product.name,
      slug: item.product.slug,
      avgRating: item.product?.avgRating,
      sku: +item.product.sku,
      containsAlcohol: item.product?.containsAlcohol ?? false,
      brand: {
        name: item.product.brand.name,
        slug: item.product.brand.slug,
      },
      thumbnail: {
        url: item.product.image.url,
      },
    },
    stepQty: item.stepQty,
  };
};

export const typeDiscountName = {
  expiration_discount: "Antimerma",
  customer_discount: "Descuento exclusivo",
  promofit: "Promofit",
  flash_sale: "Flash",
};

export const typeDiscountNameShort = {
  ...typeDiscountName,
  customer_discount: "Exclusivo",
};
