import Icon404 from "@/components/Icons/V2/Communication/404.svg?react";
import Error from "@/components/Molecules/Error";

interface Error404Props {
  withHeader?: boolean;
}
const Error404 = ({ withHeader = true }: Error404Props) => {
  return (
    <Error
      options={{
        title: "La página que buscas no se encuentra disponible",
        message:
          " Comprueba que la URL sea la correcta, intenta usando el buscador o ve a la página inicial.",
        withHeader,
        hScreen: true,
        adornment: <Icon404 fill="transparent" width={250} height={250} aria-label="Icono de Lupa" />,
      }}
      buttonsProps={{
        color: "primary",
        sizeVariant: "lg",
        buttonText: "Volver al Inicio",
        to: "/",
      }}
    />
  );
};
export default Error404;
