import { theme } from "twin.macro";
import Caption from "../Caption";
import {
  StyledDividerTagsVR3,
  StyledTagsVR3Gradient,
  StyledTagsVR3Root,
  SyleDividerDotTagsVR3,
} from "./styles";
import { isArray } from "lodash";
import { darkenHex } from "@/utils/color";
import Tooltip, { TooltipContent, TooltipTrigger } from "@/components/Atoms/Tooltip";
import Paragraph from "../Paragraph";
import { useMemo } from "react";
import { TYPE_DISCOUNT } from "@/services/checkout/type";

interface TagsVR3Props {
  label: string | string[] | undefined;
  color?: string;
  type:
    | "customLabel"
    | "discount"
    | "sponsor"
    | "free"
    | TYPE_DISCOUNT.CUSTOMER_DISCOUNT
    | TYPE_DISCOUNT.PROMOFIT
    | TYPE_DISCOUNT.EXPIRATION_DISCOUNT;
  separator?: "line" | "dot";
}

const colorsByType = {
  sponsor: {
    separatorColor: theme`colors.secondary.40`,
    background: theme`colors.neutral.90`,
    borderColor: theme`colors.neutral.70`,
    textColor: theme`colors.neutral.30`,
  },
  free: {
    separatorColor: "transparent",
    background: theme`colors.tertiary.60`,
    borderColor: "transparent",
    textColor: theme`colors.neutral.100`,
  },
};

const TagsVR3 = ({ label, color, type, separator = "line" }: TagsVR3Props) => {
  const hasCustomLabel = type === TYPE_DISCOUNT.EXPIRATION_DISCOUNT || type === "customLabel";
  const hasSponsor = type === "sponsor";
  const hasExclusiveDiscount =
    type === TYPE_DISCOUNT.CUSTOMER_DISCOUNT || type === TYPE_DISCOUNT.PROMOFIT || type === "discount";

  const colors = useMemo(() => {
    if (hasExclusiveDiscount) {
      return {
        assignedColor: theme`colors.secondary.50`,
        assignedBackground: theme`colors.secondary.50`,
        borderColor: theme`colors.secondary.50`,
        textColor: theme`colors.secondary.50`,
      };
    }

    if (hasCustomLabel) {
      return {
        assignedColor: color,
        assignedBackground: color,
        borderColor: color,
        textColor: darkenHex(color!, 0.7),
      };
    }

    return {
      assignedColor: colorsByType[type].separatorColor,
      assignedBackground: colorsByType[type].background,
      borderColor: color,
      textColor: colorsByType[type].textColor,
    };
  }, [hasExclusiveDiscount, type, hasCustomLabel]);

  const assignedColor = colors.assignedColor;
  const assignedBackground = colors.assignedBackground;
  const assignedBorderColor = colors.borderColor;
  const assignedTextColor = colors.textColor;

  const renderLabelContent = () => {
    if (isArray(label)) {
      return label.map((item, index) => {
        const isLast = index === label.length - 1;
        return (
          <div key={index} tw="flex h-full gap-s">
            <Caption fontWeight={700} tw="w-max" color={assignedTextColor}>
              {item}
            </Caption>
            {!isLast &&
              (separator === "line" ? (
                <StyledDividerTagsVR3 $color={assignedColor} />
              ) : (
                <SyleDividerDotTagsVR3 $color={assignedColor} />
              ))}
          </div>
        );
      });
    }
    return (
      <Caption fontWeight={700} color={assignedTextColor}>
        {label}
      </Caption>
    );
  };

  if (hasExclusiveDiscount) {
    return <StyledTagsVR3Gradient>{renderLabelContent()}</StyledTagsVR3Gradient>;
  }

  const tagContent = (
    <StyledTagsVR3Root
      $borderStyle={hasSponsor ? "solid" : "dashed"}
      $useTransparent={hasCustomLabel}
      $background={assignedBackground}
      $borderColor={assignedBorderColor}
    >
      {renderLabelContent()}
    </StyledTagsVR3Root>
  );

  return hasCustomLabel && type === TYPE_DISCOUNT.EXPIRATION_DISCOUNT ? (
    <Tooltip placement="bottom-end">
      <TooltipTrigger>{tagContent}</TooltipTrigger>
      <TooltipContent tw="max-w-[14.313rem] z-10">
        <Paragraph sizeVariant="sm">Producto en excelente estado, con pronta vida útil.</Paragraph>
      </TooltipContent>
    </Tooltip>
  ) : (
    tagContent
  );
};

export default TagsVR3;
