import { ORDER_MIN_AMOUNT } from "@/recourses/constants";

export const existProductCart = (items: Cart[], sku: number): Cart | null => {
  const result = items.find((item) => Number(item.product.sku) === Number(sku));
  return result ?? null;
};

export const getInfoCoupon = (coupon: CouponCheckout | null, totalItemsWithoutDiscount: number) => {
  if (!coupon) {
    return null;
  }

  const isPercentage = coupon.discountValueType === "percentage";
  let finalValuePrice = coupon.discountValue;
  if (isPercentage) {
    finalValuePrice = totalItemsWithoutDiscount * (coupon.discountValue / 100);
  }

  if (!coupon.minAmount?.gross) {
    const minOrderAmount = finalValuePrice + ORDER_MIN_AMOUNT;
    const isValidMinAmount = totalItemsWithoutDiscount > minOrderAmount;

    if (!isValidMinAmount) {
      return {
        ...coupon,
        minAmount: {
          ...coupon.minAmount,
          gross: String(minOrderAmount),
          net: String(minOrderAmount),
        },
        isValid: false,
        isPercentage,
        finalValuePrice,
      };
    }
  }

  return {
    ...coupon,
    isPercentage,
    finalValuePrice,
  };
};
