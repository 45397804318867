export enum FIELD_FILTERS {
  HUBS = "product_hubs",
  CATEGORIES = "store_categories",
  SUB_CATEGORIES = "store_categories",
  BRANDS = "product_brand",
}
export enum SORTING {
  IS_NEW = "is_new:desc",
  IS_PRIVATE_LABEL = "is_private_label:desc",
  DISCOUNT_PERCENTAGE = "product_percentage_discount_online:desc",
  IS_ANTIMERMA = "is_antimerma:desc",
}
