import { IS_PROD } from "@/recourses/constants";
import { captureException } from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import Error503 from "@/Pages/Error503";

const SentryRouteErrorFallback = () => {
  const routeError = useRouteError();

  useEffect(() => {
    if (IS_PROD) {
      captureException(routeError, { level: "fatal" });
    }
  }, [routeError]);

  return <Error503 hScreen />;
};

export default SentryRouteErrorFallback;
