import { hexToRGBA } from "@/utils/color";
import tw, { css, styled } from "twin.macro";
import { type CSSProperties } from "styled-components";
import IconRadio from "@iconsV2/Controllers/radio-empty-filled.svg?react";

interface IStyledTagsVR3RootProps {
  $background?: CSSProperties["color"];
  $borderStyle: CSSProperties["borderStyle"];
  $useTransparent: boolean;
  $borderColor?: CSSProperties["color"];
}
interface IStyledDividerTagsVR3Props {
  $color: CSSProperties["color"];
}
export const StyledTagsVR3Root = styled.div<IStyledTagsVR3RootProps>`
  ${tw`flex items-center gap-s px-s py-xxs rounded-s min-w-max cursor-pointer`}
  ${({ $background, $borderStyle, $borderColor, $useTransparent }) => css`
    background-color: ${$background ? hexToRGBA($background, $useTransparent ? 0.04 : 1) : "transparent"};
    border: 1.5px ${$borderStyle ?? "transparent"};
    border-color: ${$borderColor ?? "transparent"};
  `}
`;

export const StyledTagsVR3Gradient = styled.div`
  border: 1.5px dashed;

  ${tw`flex items-center gap-s px-s py-xxs rounded-s min-w-max border-secondary-50 bg-gradient-to-r from-secondary-90 to-secondary-80`}
`;

export const StyledDividerTagsVR3 = styled.div<IStyledDividerTagsVR3Props>`
  ${tw` w-[1px]`}
  ${({ $color }) => css`
    background-color: ${$color};
  `}
`;

export const SyleDividerDotTagsVR3 = styled(IconRadio)<IStyledDividerTagsVR3Props>`
  ${tw`w-[6px] h-[6px] my-auto`}
  ${({ $color }) => css`
    fill: ${$color};
    path {
      fill: ${$color};
    }
  `}
`;
