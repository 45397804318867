import tw, { styled } from "twin.macro";

export const StyledHambuguerButton = styled.button`
  ${tw`flex flex-col items-center justify-center gap-[0.1875rem] h-full w-auto medium:p-sm`}

  &:hover div {
    ${tw`bg-secondary-60`}
  }
`;

export const StyledHambuguerLine = styled.div<{
  $open: boolean;
}>`
  ${tw`rounded-full bg-secondary-40 transition ease-in transform duration-300 opacity-100 group-hover:opacity-80 h-[0.125rem] w-[1.25rem] medium:(h-[3px] w-[1.5rem]) `}

  ${({ $open }) => {
    if ($open) {
      return tw`first:(translate-y-[0.3125rem] medium:(translate-y-[0.375rem]) rotate-45) [&:nth-child(2)]:(opacity-0 group-hover:opacity-0) last:(-translate-y-[0.3125rem] medium:(-translate-y-[0.375rem]) -rotate-45)  `;
    }
  }}
`;
