import Label from "@/components/Atoms/Label";
import { StyledWrapperFitpoint, StyledWrapperFitpointText } from "./styles";
import IconWarning from "@iconsV2/Warnings/information-circle.svg?react";
import { theme } from "twin.macro";
import Paragraph from "@/components/Atoms/Paragraph";
import useCredits from "@/hooks/useCredits";
import { formatCurrency } from "@/utils/money";

const FitpointCard = () => {
  // combine fitPoints with friendPoints {totalCredits}
  const { creditsAvailable } = useCredits({ creditsAvailable: true });

  return (
    <StyledWrapperFitpoint>
      <StyledWrapperFitpointText>
        <Label sizeVariant="md" color={theme`colors.tertiary.100`}>
          Fitpoints
        </Label>
        <IconWarning tw={"[& path]:(fill-tertiary-100)"} />
      </StyledWrapperFitpointText>

      <Paragraph sizeVariant="md" color={theme`colors.tertiary.100`}>
        {formatCurrency(Number(creditsAvailable?.data?.gross ?? 0))}
      </Paragraph>
    </StyledWrapperFitpoint>
  );
};
export default FitpointCard;
