import Header from "@/components/Organisms/Header";
import { WrapperError, WrapperMessages, StyledRootContainer } from "./styles";
import Paragraph from "@/components/Atoms/Paragraph";
import Heading from "@/components/Atoms/Heading";
import Button, { type IButton } from "@/components/Atoms/Button";
import { Link } from "react-router-dom";
import { type ReactNode } from "react";
import { type TOrientation } from "@/types/common";
import { type CSSProperties } from "styled-components";

interface IExtendedButton extends IButton {
  buttonText?: string;
  to?: string;
}

interface ErrorProps {
  options: {
    // Required props
    title: string;
    message: ReactNode;
    adornment: ReactNode;

    // Optional props
    withHeader?: boolean;
    orientation?: TOrientation;
    textAlign?: CSSProperties["textAlign"];
    hScreen?: boolean;
    to?: string;
    onClick?: () => void;
  };

  buttonsProps: IExtendedButton;
}

const Error = ({ options, buttonsProps }: ErrorProps) => {
  const {
    title,
    message,
    withHeader = false,
    orientation = "horizontal",
    textAlign = "start",
    hScreen = false,
    adornment,
  } = options;

  if (!options) {
    throw new TypeError("Error: options is required");
  }

  // Determine if we should use Link or button
  const ButtonComponent = buttonsProps.to ? Link : "button";
  const buttonProps = buttonsProps.to ? { to: buttonsProps.to } : {};

  return (
    <StyledRootContainer $hScreen={hScreen} $withHeader={withHeader}>
      {withHeader && (
        <div tw="w-full">
          <Header />
        </div>
      )}
      <WrapperError $orientation={orientation}>
        {adornment}
        <WrapperMessages $textAlign={textAlign}>
          <Heading type="h3">{title}</Heading>
          <Paragraph sizeVariant="lg">{message}</Paragraph>

          <span>
            <Button
              onClick={buttonsProps.onClick}
              as={ButtonComponent}
              sizeVariant={buttonsProps.sizeVariant}
              color={buttonsProps.color}
              startIcon={buttonsProps.startIcon}
              endIcon={buttonsProps.endIcon}
              fullWidth={buttonsProps.fullWidth}
              {...buttonProps} // Spread the dynamic props
            >
              {buttonsProps.buttonText ? buttonsProps.buttonText : "Continuar"}
            </Button>
          </span>
        </WrapperMessages>
      </WrapperError>
    </StyledRootContainer>
  );
};

export default Error;
