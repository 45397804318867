import tw, { styled } from "twin.macro";

export const StyledWrapperProfile = styled.div`
  ${tw`flex items-center gap-s rounded-t-lg px-s py-1.5 cursor-pointer
    medium:(py-2 gap-sm px-sm)
    large:(p-4)
  `}
`;

export const StyledWrapperFitpointList = styled.div`
  ${tw`flex flex-col p-0 bg-neutral-100 mb-s`};
`;

export const StyledWrapperItemsMenu = styled.div`
  ${tw`flex flex-col items-stretch !px-0
    all-child:(border-b border-neutral-90 px-s py-m last:(border-none) medium:(p-sm) large:(p-m) )
  `}
`;

export const StyledWrapperItemsMenuButton = styled.div`
  ${tw`w-full`}

  & > * {
    &:hover {
      ${tw`!bg-error-90`}
    }
  }
`;
