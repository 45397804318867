import { memo, useCallback } from "react";
import tw, { theme } from "twin.macro";
import { Link } from "react-router-dom";
import type { IProductCardGallery } from "@/types/productGallery";

import Image from "@atoms/Image";
import Heading from "@atoms/Heading";
import Paragraph from "@atoms/Paragraph";
import Label from "@atoms/Label";
import CounterInput from "@atoms/CounterInput";
import Button from "@atoms/Button";
import ProductCardTag from "@atoms/ProductCardTag";
import TagsVR3 from "@atoms/TagsVR3";

import { formatCurrency } from "@/utils/money";
import useProductCardGallery from "@/hooks/useProductCardGallery";
import IconMessageAlertSquare from "@iconsV2/Communication/message-alert-square.svg?react";
import IconCart from "@iconsV2/Interrface/cart-01.svg?react";
import IconMessageAlertCircle from "@iconsV2/Communication/message-alert-circle.svg?react";
import IconTrash from "@iconsV2/Interrface/trash-02.svg?react";
import type { TSize } from "@/types/common";
import { MAX_UNIT_QUANTITY } from "@/recourses/constants";
import { getDisplayBrand } from "@/utils/getDisplayBrand";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { typeDiscountNameShort } from "@/utils/product";
import { TYPE_DISCOUNT } from "@/services/checkout/type";

import {
  StyledWrapperFigure,
  StyledWrapperHead,
  StyledWrapperPGCardSearch,
  StyledWrapperBody,
  StyledWrapperContent,
  StyledWrapperHelpMessage,
} from "./styles";

enum ProductCardGallerySearchEnum {
  FULL = "full",
  CONTENT = "content",
}
interface IProductCardGallerySearch extends Omit<IProductCardGallery, "sizeVariant"> {
  showButtonCart?: boolean;
  showUnits?: boolean;
  sizeCounterInput?: TSize;
  extraValidation?: {
    units?: boolean;
  };
  clickableArea?: `${ProductCardGallerySearchEnum}`;
  onCloseShoppingCartMenu?: () => void;
}

const ProductCardGallerySearch = ({
  item,
  showButtonCart = true,
  showUnits = false,
  active = false,
  disabled = false,
  sizeCounterInput = "medium",
  extraValidation = {
    units: false,
  },
  clickableArea = ProductCardGallerySearchEnum.CONTENT,
  onCloseShoppingCartMenu,
}: IProductCardGallerySearch) => {
  const {
    // states:
    showInput,
    productQuantity,
    isProductAvailable,
    percentageDiscount,
    productFinalPricePerStepQty,
    productOriginalPricePerStepQty,

    // functions:
    getGramans,
    handleAddToCart,
    handledToCleanCart,
    handleIncrement,
    handleToDecrement,
    handleToChangeInput,
    handleGoToBrand,
  } = useProductCardGallery({
    product: item,
  });

  const isMedium = useMediaQuery("(min-width: {medium})");
  const Tag = useCallback(() => {
    // if (item?.bestSeller) {
    //   return <ProductCardTag type="bestSeller" tw="rounded-round shadow-none" />;
    // }

    if (item.details.isNew) {
      return <ProductCardTag type="new" />;
    }

    if (item.details.isPromoted) {
      return <ProductCardTag type="promoted" />;
    }

    if (
      (item.details.onSale && !item.details.customLabel) ||
      item.details.customLabel === TYPE_DISCOUNT.PROMOFIT
    ) {
      return <TagsVR3 type="discount" label={["Promofit", `- ${percentageDiscount}%`]} />;
    }

    if (item.details.customLabel && percentageDiscount > 0) {
      return (
        <TagsVR3
          type={
            typeDiscountNameShort[item.details.customLabel]
              ? (item.details.customLabel as TYPE_DISCOUNT.CUSTOMER_DISCOUNT)
              : "customLabel"
          }
          label={[
            typeDiscountNameShort[item.details.customLabel] ?? item.details.customLabel,
            `- ${percentageDiscount}%`,
          ]}
          color={item.details.customLabelBackgroundColor}
        />
      );
    }

    return null;
  }, [
    // item.bestSeller,
    item.details.isNew,
    item.details.isPromoted,
    item.details.customLabel,
  ]);

  const dismissShoppingCartMenu = () => {
    if (!isMedium) {
      onCloseShoppingCartMenu?.();
    }
  };
  const navigateToBrand = (e: React.MouseEvent<HTMLHeadingElement>) => {
    handleGoToBrand(e);
    dismissShoppingCartMenu();
  };
  const RemainingUnit = useCallback(
    ({ error }: { error?: boolean }) => {
      return (
        <StyledWrapperHelpMessage>
          <IconMessageAlertCircle css={error && tw`[& > path]:(stroke-error-40)`} />
          <Paragraph sizeVariant="sm" color={error ? theme`colors.error.40` : theme`colors.neutral.10`}>
            Quedan {item.details.maxQtyToOrder} {getGramans(item.details.maxQtyToOrder)}
          </Paragraph>
        </StyledWrapperHelpMessage>
      );
    },
    [item?.details.maxQtyToOrder]
  );

  const HelpMessage = useCallback(() => {
    if (!isProductAvailable) {
      return (
        <StyledWrapperHelpMessage>
          <IconMessageAlertSquare tw="[&>path]:stroke-error-40" />
          <Paragraph sizeVariant="sm" size="medium" color={theme`colors.error.40`}>
            Sin unidades
          </Paragraph>
        </StyledWrapperHelpMessage>
      );
    }

    if (item.details.maxQtyToOrder < MAX_UNIT_QUANTITY || extraValidation?.units) {
      return <RemainingUnit error={extraValidation.units} />;
    }
    return null;
  }, [item?.details.maxQtyToOrder, showInput, isProductAvailable, extraValidation?.units]);

  return (
    <StyledWrapperPGCardSearch
      $isPromoted={item.details.isPromoted}
      $active={active}
      as={clickableArea === ProductCardGallerySearchEnum.FULL ? Link : "div"}
      to={clickableArea === ProductCardGallerySearchEnum.FULL ? `/p/${item.product.slug}` : undefined}
    >
      {/* Imagen  */}
      {clickableArea === ProductCardGallerySearchEnum.FULL ? (
        <StyledWrapperFigure $disabled={disabled}>
          <Image src={item.product.image.url} alt={item.product.name} title={item.product.name} />
        </StyledWrapperFigure>
      ) : (
        <Link
          to={`/p/${item.product.slug}`}
          title={item.product.name}
          state={{
            product: item,
          }}
          onClick={dismissShoppingCartMenu}
        >
          <StyledWrapperFigure $disabled={disabled}>
            <Image src={item.product.image.url} alt={item.product.name} />
          </StyledWrapperFigure>
        </Link>
      )}

      <StyledWrapperContent>
        <div>
          <StyledWrapperHead tw="flex items-center justify-between">
            <Heading
              type="h6"
              disabled={disabled}
              onClick={(e) => navigateToBrand(e)}
              tw="cursor-pointer w-min"
            >
              {getDisplayBrand(item.product.brand.name)}
            </Heading>

            <Tag />
          </StyledWrapperHead>
          <Paragraph
            sizeVariant="sm"
            disabled={disabled}
            as={clickableArea === ProductCardGallerySearchEnum.CONTENT ? Link : "div"}
            to={
              clickableArea === ProductCardGallerySearchEnum.CONTENT ? `/p/${item.product.slug}` : undefined
            }
            title={item.product.name}
            state={{
              product: item,
            }}
            onClick={dismissShoppingCartMenu}
          >
            {item.product.name}
          </Paragraph>
        </div>

        <StyledWrapperBody>
          <div tw="flex gap-xxs items-center">
            <Label sizeVariant="lg" color={theme`colors.primaryV2.10`} disabled={disabled}>
              {formatCurrency(productFinalPricePerStepQty ?? 0)}
            </Label>

            {percentageDiscount > 0 && (
              <div tw="flex flex-col items-center">
                <Paragraph sizeVariant="sm" color={theme`colors.neutral.30`} tw="line-through">
                  {formatCurrency(productOriginalPricePerStepQty ?? 0)}
                </Paragraph>

                <Paragraph sizeVariant="sm" color={theme`colors.neutral.30`}>
                  {percentageDiscount}%
                </Paragraph>
              </div>
            )}
          </div>
          <div tw="w-min flex flex-col gap-xxs items-end">
            {showButtonCart && isProductAvailable && (
              <>
                {showInput ? (
                  <CounterInput
                    showIconRemove
                    tw="[&>div]:gap-xxs w-[9.5rem] medium:(w-[9.8rem] large:(w-[10rem]))"
                    value={String(productQuantity)}
                    onRemove={handledToCleanCart}
                    onIncrement={() => handleIncrement(+item.product.sku)}
                    onDecrement={handleToDecrement}
                    onChange={(value) => handleToChangeInput(value as number)}
                    maxUnits={item.details.maxQtyToOrder}
                    id="counter-input"
                    error={productQuantity > item.details.maxQtyToOrder}
                    size={sizeCounterInput}
                    measureType={item.details.measureTypeDisplayShort ?? ""}
                    stepQty={item.stepQty}
                  />
                ) : (
                  <Button
                    sizeVariant="md"
                    endIcon={<IconCart />}
                    disabled={!isProductAvailable}
                    onClick={handleAddToCart}
                  />
                )}
              </>
            )}

            {showUnits && (
              <div tw="flex items-center w-max gap-xs">
                <HelpMessage />

                {!isProductAvailable && showButtonCart && (
                  <Button
                    endIcon={<IconTrash />}
                    onClick={handledToCleanCart}
                    color="secondary"
                    variant="outlined"
                    sizeVariant="md"
                  />
                )}
              </div>
            )}
          </div>
        </StyledWrapperBody>
      </StyledWrapperContent>
    </StyledWrapperPGCardSearch>
  );
};

export default memo(ProductCardGallerySearch, (prevProps, nextProps) => {
  return (
    prevProps.item.product.id === nextProps.item.product.id &&
    prevProps.item.price.finalPrice === nextProps.item.price.finalPrice &&
    prevProps.item.details.maxQtyToOrder === nextProps.item.details.maxQtyToOrder &&
    prevProps.disabled === nextProps.disabled
  );
});
